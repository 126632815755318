.App {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
}

/* Prevent body scrolling when menu is open */
/* body.menu-open {
  overflow: hidden;
} */


