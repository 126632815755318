/* Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Home Container */
.HomeContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background-size: cover;
  overflow: hidden;
  padding-bottom: 20px;
  /* overflow-y: auto; */
}

/* Header */
.HomeContainer header {
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Logo */
.HomeContainer .Logo {
  width: 100%;
  text-align: center;
}

.HomeContainer .Logo a {
  font-family: "Futura", "Trebuchet MS", Arial, sans-serif;
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: .15em;
  font-weight: 500;
  color: #00121a;
  text-decoration: none;
  display: inline-block;
  padding: 0;
  margin: 0 auto;
}

.HomeContainer .Logo a:hover {
  color: #0022a3;
}

/* Home Gallery */
.HomeGallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  width: 80%;
  max-width: 1200px;
  margin: 20px auto;
  /* flex-grow: 1; */
}

/* Home Items */
.HomeItem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  overflow: hidden;
}

/* Ensure Links take full size of HomeItem */
.HomeItem a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 100%;
  color: inherit;
}

/* Home Images */
.HomeImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

/* Image Text */
.ImageText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2rem;
  font-family: 'Arial', sans-serif;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

/* Overlay */
.HomeItemOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HomeItem:hover .HomeItemOverlay {
  opacity: 1;
}

.HomeItem:hover .ImageText {
  opacity: 1;
}

/* Text Below Image */
.ImageTextBelow {
  display: none;
  color: #00121a;
  font-size: 1.25rem;
  font-family: 'Arial', sans-serif;
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 1000px) {
  .ImageText {
    font-size: 1.8rem;
  }
}

/* -------------------- tablet -------------------- */
@media (max-width: 900px) {
  .HomeGallery {
    grid-template-columns: 1fr;
  }

  .HomeItem {
    height: 450px;
    /* padding-bottom: 2em; */
  }

  .HomeItemOverlay, .ImageText {
    display: none;
  }

  .HomeImage {
    width: 100%;
    height: 90%;
    object-fit: cover;
    transition: opacity 0.3s ease;
  }

  .ImageText {
    font-size: 1.5rem;
  }

  .ImageTextBelow {
    display: block;  
  }
}

/* -------------------- mobile -------------------- */
@media (max-width: 500px) {
  .HomeContainer {
    width: 100%;
    min-height: 100vh;
    margin: 27px auto;
  }

  .HomeContainer header {
    margin-left: 25px;
    margin-right: 0;
    width: 100%;
    height: 60px;
  }

  .HomeGallery {
    grid-gap: 10px;
    width: 95%;
  }

  .HomeItem {
    height: 300px;
    padding-bottom: 0;
    margin-bottom: 20px; /* Ensure consistent spacing */
  }

  .HomeItem:last-child {
    margin-bottom: 0; /* Remove extra margin after the last item */
  }

  .HomeImage {
    border-radius: 25px;
  }

  .HomeItem img {
    margin-bottom: 0;
  }

  /* .ImageTextBelow {
    margin-bottom: 20px;
    font-size: 1.25rem;
  } */
}
