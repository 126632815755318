/* Global Styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
  
body {
    overflow-x: hidden;
}
  
/* Flipbook Container */
.BookContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    overflow: hidden;
}
  
/* Flipbook Wrapper */
.FlipBookWrapper {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    box-sizing: border-box;
    cursor: pointer;
}
  
/* Page Styles */
.page {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.page img {
    width: 100%;
    height: auto;
    object-fit: cover; /* Adjust to fit the image within the page */
}

/* Hide content on the front cover */
.cover .pageContent {
    display: none;
}

.LowerText {
    font-size: .8em;
    margin-bottom: 25px;
}

/* Media Queries */

/* Desktop */
@media (min-width: 901px) {
    .FlipBookWrapper {
        width: 80%;
        height: 80%;
    }
}
  
/* Tablet */
@media (max-width: 900px) and (min-width: 501px) {
    .FlipBookWrapper {
        width: 90%;
        height: 90%;
    }
}
  
/* Mobile */
@media (max-width: 500px) {
    .FlipBookWrapper {
        width: 100%;
        height: 100%;
        padding: 0;
    }
}
