.LandingPage {
  margin: 0;
  height: 100vh;
  background: url(../img/SALT_01_w.gif) no-repeat center center fixed;
  background-size: cover;
  z-index: 1;
}

.LandingPage header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95px;
  position: relative;
}

.LandingPage .LogoWrapper {
  display: flex;
  justify-content: center;
  flex: 1;
}

.LandingPage .Logo {
  width: 250px;
  height: auto;
}

.LandingPage h1 {
  font-family: "Futura", "Trebuchet MS", Arial, sans-serif;
  font-size: 30px;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: .15em;
  font-weight: 500;
  font-style: normal;
  color: #fff;
  text-decoration: none;
}

.LandingPage main {
  height: calc(100% - 125px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.LandingPage button {
  background-color: rgba(255, 255, 255, 0);
  border: 2px solid white;
  border-radius: 50px;
  height: 95px;
  font-family: "Futura", "Trebuchet MS", Arial, sans-serif;
  color: white;
  padding: 15px 20px;
  text-transform: uppercase;
  font-size: .8em;
  letter-spacing: .15em;
  font-weight: 800;
  cursor: pointer;
}

.LandingPage button:hover {
  background-color: white;
  color: rgb(0, 0, 0);
}


