/* Global Reset */
* {
  margin: 0;
  font-family: "Allerta";
  text-decoration: none;
}

.NavBar {
  width: 100%;
  background-color: #fff;
  position: relative;
}

/* Header Styling */
.Header {
  display: flex;
  justify-content: center; /* Center the contents horizontally */
  align-items: center;
  height: 95px;
  position: relative; /* Make sure navigation can be positioned relative to this */
}

/* Logo Wrapper */
.LogoWrapper {
  display: flex;
  justify-content: center; /* Center the logo horizontally */
  flex: 1;
}

/* Logo Styling */
.Logo {
  width: 250px; /* Adjust the size as needed */
  height: auto; /* Maintain aspect ratio */
}

.Logo a {
  cursor: pointer;
}

/* Navigation Styling */
.Navigation {
  display: flex;
  justify-content: flex-end; /* Align navigation items to the right */
  align-items: center;
  position: absolute;
  right: 20px; /* Adjust the right position as needed */
  top: 50%; /* Center vertically within the Header */
  transform: translateY(-50%); /* Adjust for perfect vertical centering */
}

.CustomIcon {
  color: #555555;
}

.NavBar nav ul {
  display: flex;
  justify-content: space-evenly; 
  list-style-type: none;
  margin: 25px auto;
  width: 150px;
}

.NavBar .Hamburger {
  margin-left: 20px;
  width: 35px;
  height: 85px;
}

.NavBar .Social svg path {
  cursor: pointer;
}

.NavBar .Social svg path:hover {
  color:  #0022a3;
}

.NavBar .Social {
  display: none;
}

.MobileNavigation {
  display: none;
}



@media (max-width: 1100px) {
  .Navigation {
    display: none;
  }

  .MobileNavigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #fff;
  }

  .LogoWrapper {
    margin: 30px 0;
  }

  .NavBar .Logo {
    display: none;
    z-index: 99999;
  }

  .MobileLogo {
    display: block;
    width: 250px;
    height: auto;
    margin-left: 35px;
    z-index: 9999;
  }


  .NavBar nav ul {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-top: 70px;
    padding-bottom: 125px;
  }

  .NavBar nav ul li {
    text-align: left;
    margin: 40px auto;
  }

  .NavBar nav ul li a {
    font-size: 26px;
    color: #333;
  }

  .NavBar nav ul li a:hover {
    color:  #0022a3;
  }

  .NavBar .MobileNavigation .Hamburger {
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    z-index: 9999;
  }

  .NavBar .Social {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    padding: 20px 25px;
  }

  .NavBar .Social li svg {
    height: 45px;
  }

  .NavBar .Social li {
    margin-top: 0;
    padding: 10px 25px;
  }
}


@media (max-width: 500px) {

  }

